import React from 'react';
import PropTypes from 'prop-types';
import { Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';

const FileColour = (format) => {
  if (format === 'pdf') {
    return '#9d3234';
  }

  if (format === 'doc' || format === 'docx' || format === 'w') {
    return '#21a9d3';
  }

  if (format === 'xls' || format === 'xlsx' || format === 'x') {
    return '#227547';
  }

  return '#222';
};

const DocumentIcon = ({ clubDocument }) => (
  <Shadow>
    <Space vertical={0} horizontal="auto">
      <Rectangle
        fill="white"
        radius="rounded"
        style={{
          lineHeight: '110px',
          position: 'relative',
          textAlign: 'center',
          width: 90,
        }}
        component="div"
      >
        <Rectangle
          fill="#F1F1F1"
          radius="roundedBottomLeft"
          style={{
            height: 18,
            position: 'absolute',
            right: -4,
            top: -4,
            width: 18,
          }}
        />
        <Typography color={FileColour(clubDocument.fileType)} preset="document">
          {clubDocument.fileType}
        </Typography>
      </Rectangle>
    </Space>
  </Shadow>
);

DocumentIcon.propTypes = {
  clubDocument: PropTypes.shape({
    file_size: PropTypes.string,
    fileType: PropTypes.string,
    title: PropTypes.string,
    private: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired,
};

export default DocumentIcon;
