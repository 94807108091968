import { Cushion, FlexContainer, Rectangle, Space, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import PublishedDate from '../published-date';
import ShareButton from '../social/share-button';
import ArticleAlbum from './album';
import Header from './header';

const Article = ({
  title,
  author,
  tagline,
  shareProps,
  renderSubheader,
  imageAlt,
  imageSrc,
  noImage,
  children,
  published,
  vimeoId,
  videoUrl,
  team,
  album,
  style,
}) => (
  <Rectangle fill="#FFF" component="div" style={{ width: '100%', ...style }}>
    <Header
      vimeoId={vimeoId}
      videoUrl={videoUrl}
      imageAlt={imageAlt}
      imageSrc={imageSrc}
      team={team}
      noImage={noImage}
    />
    <Cushion
      all="small"
      component="div"
      responsive={[{ minWidth: 'fullHeader', props: { horizontal: '0' } }]}
    >
      <Typography component="h1" preset="title--large" transform="none" style={{ marginTop: 0 }}>
        {title}
      </Typography>

      {renderSubheader()}
      <Space top="medium" bottom="small">
        {author || shareProps ? (
          <FlexContainer justifyContent="space-between" alignItems="center">
            {author && (
              <FlexContainer flexDirection="column">
                <Typography size={16} color="dustygrey" weight="bold">
                  {author.name}
                </Typography>
                <Typography size={14} color="dustygrey">
                  <PublishedDate date={published} showTime relative={false} />
                </Typography>
              </FlexContainer>
            )}
            {shareProps && <ShareButton {...shareProps} withText />}
          </FlexContainer>
        ) : (
          <PublishedDate date={published} showTime relative={false} />
        )}
      </Space>

      <Space bottom="medium">
        <Rectangle
          fill="#d9d9d9"
          style={{ height: 1, width: '100%', display: 'block', marginInlineStart: 0 }}
          component="hr"
          align="left"
        />
      </Space>

      {tagline && (
        <Space bottom="medium">
          <Typography component="h2" preset="staticSubtitle--large" weight="bold" color="black">
            {tagline}
          </Typography>
        </Space>
      )}

      <Typography
        component="div"
        preset="body--large"
        color="black"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </Typography>

      {album && album.images?.length > 0 && <ArticleAlbum images={album.images} />}
    </Cushion>
  </Rectangle>
);

Article.defaultProps = {
  album: null,
  author: null,
  tagline: null,
  imageAlt: '',
  imageSrc: null,
  shareProps: null,
  showHeaderImage: false,
  renderSubheader: () => {},
  noImage: false,
  published: null,
  style: {},
  team: null,
  vimeoId: null,
  videoUrl: null,
};

Article.propTypes = {
  album: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  author: PropTypes.shape({
    name: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  shareProps: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  renderSubheader: PropTypes.func,
  noImage: PropTypes.bool,
  showHeaderImage: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string,
  team: PropTypes.shape(),
  published: PropTypes.string,
  videoUrl: PropTypes.string,
  vimeoId: PropTypes.string,
};

export default Article;
