import {
  AbsoluteContainer,
  Badge,
  Image,
  ImageResizer,
  Rectangle,
  ShimmerImage,
  Typography,
} from '@pitchero/react-ui';
import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import VideoPlayer from '../video/dynamic';
import SixteenByNineFrame from '../video/sixteen-by-nine-frame';
import useBreakpoint from '../../lib/use-breakpoint';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { getCurrentClub } from '../../store/clubs/selectors';

const Header = ({ videoUrl, vimeoId, imageSrc, imageAlt, team, noImage, club }) => {
  const isMobile = useBreakpoint();
  const [vimeoLoaded, setVimeoLoaded] = useState(false);

  if (videoUrl || vimeoId) {
    return (
      <SixteenByNineFrame>
        {vimeoId ? (
          <>
            <ShimmerImage
              preset="fullWidthAndHeight"
              style={{ zIndex: 999, display: vimeoLoaded ? 'none' : 'auto' }}
            />
            <AbsoluteContainer bottom={0} top={0} left={0} right={0}>
              <Vimeo
                video={vimeoId}
                autoplay
                className="vimeoPlayer"
                onLoaded={() => {
                  setVimeoLoaded(true);
                }}
              />
            </AbsoluteContainer>
          </>
        ) : (
          <VideoPlayer
            videoOptions={{
              autoplay: false,
              controls: true,
              aspectRatio: '16:9',
              poster: ImageResizer.resizeUrl(imageSrc, {
                h: '900',
                w: '1600',
                t: 'square',
              }),
              videoUrl,
            }}
            shimmerPreset="fullWidthAndHeight"
          />
        )}
      </SixteenByNineFrame>
    );
  }
  // Image wanted, but no image src, so show placeholder shield
  if (!noImage && !imageSrc) {
    return (
      <Rectangle
        fill="primary"
        style={{
          width: '100%',
          height: 'auto',
          aspectRatio: '16 / 9',
          position: 'relative',
          display: 'block',
        }}
        component="div"
      >
        <AbsoluteContainer
          top="50%"
          left="50%"
          style={{
            transform: 'translate(-50%, -50%)',
            width: '40%',
            height: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {club?.logo ? (
            // I don't think we can set an explicit width and height here as
            // the club logos are not all going to be the same aspect ratio and
            // we don't have the original dimensions available in the data
            <Image src={club.logo} alt={club.name} width="100%" height="auto" />
          ) : (
            <Badge size="100%" primary="primary" secondary="secondary" />
          )}
        </AbsoluteContainer>
        {team?.name && (
          <AbsoluteContainer top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)' }}>
            <Typography
              size={isMobile ? '8vw' : '6vw'}
              preset="name"
              color="primaryContrast"
              style={{ whiteSpace: 'nowrap' }}
            >
              {team.name}
            </Typography>
          </AbsoluteContainer>
        )}
      </Rectangle>
    );
  }

  // Image wanted, and image src available, so load image
  if (!noImage && imageSrc) {
    return (
      <Image
        src={imageSrc}
        alt={imageAlt}
        loading="eager"
        preset="headline"
        shimmerOnLoad
        width={491}
        height={276}
        style={{ width: '100%', height: 'auto', aspectRatio: '16 / 9' }}
      />
    );
  }

  // No image wanted
  return null;
};

Header.defaultProps = {
  videoUrl: null,
  vimeoId: null,
  team: null,
};

Header.propTypes = {
  videoUrl: PropTypes.string,
  vimeoId: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  noImage: PropTypes.bool.isRequired,
  team: PropTypes.shape(),
  club: CLUB_PROP_TYPES.isRequired,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps)(Header);
